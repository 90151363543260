import React from 'react'
import PropTypes from 'prop-types'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'

import Layout from '../components/Layout'
import CallToAction from '../components/CallToAction'
import Seo from '../components/SEO'
import Animation from '../components/Animation'
import animationData from '../json/404.json'

const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="404" description="404" location={location} />
      <div className="mx-6 lg:mx-10 pt-10 lg:pt-40 lg:px-1/24 ">
        <Animation
          className="w-4/5 sm:w-1/2 mx-auto mb-16"
          animationData={animationData}
          animationInView={true}
          animationHasPlayed={true}
        />
        <h1 className="text-lg lg:text-2xl font-bold text-pink tracking-wider text-center -mb-10">
          Oh no! this page does not seem to exist.
        </h1>
      </div>
      <CallToAction customTitle="Try one of these instead" />
    </Layout>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default withPrismicUnpublishedPreview(NotFoundPage)
